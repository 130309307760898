
.footer-container {
    display: flex;
    background-color: white;
    height: 7vh;
    justify-content: space-evenly;
    align-items: center;
    width: 100vw;
  }

  .with-border-top {
    border-top: 1px solid black;
  }
  

  @media (min-width: 769px) {
    .footer-container {
      flex-direction: row;
    }
  }
  

  @media (max-width: 769px) {
    .footer-container {
      flex-direction: column;
      height: auto;
    }
  }


