.navbar {
    background-color: black; /* Set the background color */
    color: white; /* Set text color */
  }
  
  /* Style the navigation links */
  .navbar .nav-link {
    color: white; /* Set text color for regular links */
    margin: 0 15px; /* Adjust the margin to control spacing between items */
  }
  
  /* Style the navigation links on hover */
  .navbar .nav-link:hover {
    color: white; /* Set text color on hover */
    text-decoration: underline; /* Add underline on hover */
  }
  
  /* Style the hamburger menu icon */
  .navbar-toggler-icon {
    border: none !important; /* Remove any border */
    opacity: 1 !important; /* Ensure full opacity */
    color: white !important; /* Set the color to white */
  }
  
  /* Media query for LG (large) breakpoint */
  @media (max-width: 1100px) {
    .white-hamburger-menu .navbar-toggler-icon {
      display: block !important; /* Make the hamburger icon visible */
    }
  
    /* Change the color of text for debugging purposes */
    .navbar .nav-link {
      color: white; /* Change the text color to red */
    }
  }
  