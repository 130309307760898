/* Skills.module.css */

.your-component {
  display: flex;
  flex-direction: column; /* Start with column layout for mobile */
}

.image-container,
.text-container {
  width: 100%; /* Take up the full width of the screen */
}

.image-container img {
  width: 100%;
  height: auto; /* Maintain image aspect ratio */
}

.text-container {
  background-color: blue;
  color: white;
  padding: 20px;
}

.text-content h1 {
  font-size: 24px;
  font-weight: 700;
}

.text-content h2 {
  font-size: 18px;
}

.text-content p {
  font-size: 18px;
  margin-top: 5vh;
  margin-left: 5vw;
}

.text-content p::after {
  content: "\2713";
  color: rgb(11, 207, 11);
  margin-left: 1vw;
}

/* Apply styles for screens larger than 701px */
@media (min-width: 701px) {
  .your-component {
    flex-direction: row; /* Switch to row layout for larger screens */
    height: 60vh;
  }

  .image-container {
    flex: 0.6; /* Image takes up 60% of the width */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .image-container img {
    width: 100%;
    height: 100%; /* Both image and text containers have the same height */
  }

  .text-container {
    flex: 0.4; /* Text takes up 40% of the width */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
